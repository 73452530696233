import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import getLinkFromAbsolutePath from '../../utils/getLinkFromAbsolutePath'; // eslint-disable-line
import { useComponents } from '../../utils/multiBrandedTheme';
import { Link } from 'gatsby';
import { useThemeContext } from '../ThemeContext';

const Tabs = ({ items, pathName }) => {
  const { Box, Flex } = useComponents();
  const [theme] = useThemeContext();

  const TabItem = styled(({ isActive, ...rest }) => <Box {...rest} />)`
    font-family: ${({ theme: { fontFamilyBrandPrimary } }) =>
      fontFamilyBrandPrimary};
    color: ${({ isActive, theme }) =>
      isActive ? theme.colorGrey80 : theme.colorGrey60};

    &::after {
      content: '';
      position: absolute;
      bottom: ${({ theme }) => `-${theme.space2}`};
      left: 50%;
      width: 100%;
      transform: translateX(-50%) scaleX(0);
      height: ${({ theme }) => theme.space2};
      border-radius: ${({ theme }) => `${theme.space2} ${theme.space2} 0 0`};
      background-color: ${({ theme }) => theme.colorSecondaryBase};
      animation-name: grow;
      animation-duration: ${({ theme }) => theme.motionDurationSlow};
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-play-state: paused;
    }

    ${props =>
      props.isActive &&
      `&::after {
    animation-play-state: running;

}`}

    @keyframes grow {
      from {
        transform: translateX(-50%) scaleX(0);
      }
      to {
        transform: translateX(-50%) scaleX(1);
      }
    }

    &:hover {
      color: ${({ theme }) => theme.colorGrey80};
    }
  `;

  if (items && items.length > 1) {
    return (
      <Flex
        position="sticky"
        top={10}
        mb={7}
        pb={2}
        bgColor="colorWhiteBase"
        borderBottomWidth={1}
        borderBottomStyle="solid"
        borderBottomColor="colorGrey30"
        zIndex={100}
      >
        {items.map(item => {
          const tabIsActive = pathName.split('/').pop() === item.parent.name;

          return (
            <Link
              key={item.fileAbsolutePath}
              to={`${getLinkFromAbsolutePath(
                item.fileAbsolutePath
              )}?brand=${theme}`}
            >
              <TabItem
                p={5}
                position="relative"
                fontWeight="medium"
                textTransform="capitalize"
                isActive={tabIsActive}
              >
                {item.parent.name}
              </TabItem>
            </Link>
          );
        })}
      </Flex>
    );
  }

  return null;
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        parent: PropTypes.shape({
          name: PropTypes.string,
          fileAbsolutePath: PropTypes.string,
        }),
      }),
    })
  ).isRequired,
  pathName: PropTypes.string.isRequired,
};

Tabs.defaultProps = {};

export default Tabs;
