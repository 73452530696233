import React from 'react';
import PropTypes from 'prop-types';
import { useComponents } from '../../utils/multiBrandedTheme';
import DocsIllustration from '../DocsIllustration';
import Columns from '../Columns';

const PageHeader = ({
  title = 'Page Title',
  parent,
  intro,
  illustration,
  children,
  pathName,
}) => {
  const { Caption, Heading, Text, Box, Hidden, Screenreader } = useComponents();
  return (
    <Box position="relative">
      <Columns
        cols={{
          lg: '2fr 1fr',
        }}
      >
        <Box>
          {parent && (
            <Caption
              textTransform="uppercase"
              fontWeight="medium"
              color="colorPrimary70"
              mb={5}
              className="DocSearch-lvl2"
            >
              {parent}
            </Caption>
          )}
          {title && (
            <Heading
              color="grey80"
              className="DocSearch-lvl1"
              fontFamily="primary"
              mb={7}
            >
              <span>{title}</span>
              <Screenreader> ({pathName.split('/')[3]})</Screenreader>
            </Heading>
          )}
          {intro && (
            <Text
              as="p"
              className="DocSearch-lvl3"
              mb={9}
              level={3}
              color="grey60"
              fontFamily="primary"
              fontWeight="medium"
              size="Heading4"
            >
              {intro}
            </Text>
          )}
          {children}
        </Box>
        {illustration && (
          <Hidden below="lg">
            <DocsIllustration name={illustration} />
          </Hidden>
        )}
      </Columns>
    </Box>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  parent: PropTypes.string,
  intro: PropTypes.string,
};
export default PageHeader;
