/* eslint-disable import/extensions, import/no-unresolved, import/no-unassigned-import, import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import SideNav from 'components/SideNav';
import Query from '../../queries/navQuery';
import TopNav from '../TopNav';
import Main from './Main';
import Content from './Content';
import PageHeader from '../PageHeader';
import Footer from '../Footer';
import SEO from '../Seo';
import Tabs from 'components/Tabs';
import Subnav from 'components/Subnav';
import sortByParentName from '../../utils/sortByParentName';
import sortByOrder from '../../utils/sortByOrder';
import BrandThemeProvider from './BrandThemeProvider';
import Container from '../Container';

const Layout = ({ children, pageContext }) => {
  const frontmatter = pageContext && pageContext.frontmatter;

  const sortItems = items => {
    const orderedItems = items
      .map(item => item.node)
      .filter(item => item.frontmatter.order)
      .sort(sortByOrder);
    const unorderedItems = items
      .map(item => item.node)
      .filter(item => !item.frontmatter.order);

    return [...orderedItems, ...unorderedItems];
  };

  return (
    <Query
      render={menuItems => (
        <BrandThemeProvider>
          <SEO
            title={frontmatter.name || ''}
            keywords={[
              'chameleon',
              'mediahuis',
              'design',
              'system',
              'design-system',
            ]}
          />
          <TopNav />
          <Location>
            {({ location }) => (
              <React.Fragment>
                <Main>
                  <Container pt={11}>
                    {frontmatter && (
                      <PageHeader
                        title={frontmatter.name}
                        parent={location.pathname.split('/')[1]}
                        intro={frontmatter.intro}
                        pathName={location.pathname}
                        illustration={frontmatter.illustration}
                      >
                        <Subnav
                          pathName={location.pathname}
                          headings={
                            location.pathname &&
                            menuItems
                              .map(({ items }) => items)
                              .flat(1)
                              .filter(({ node }) => {
                                const pathname = location.pathname.split('/');
                                if (pathname[3]) {
                                  const path = `${pathname[1]}/${pathname[2]}/${pathname[3]}`;
                                  return node.fileAbsolutePath.includes(path);
                                } else {
                                  const path = `${pathname[1]}/${pathname[2]}`;
                                  return node.parent.relativeDirectory === path;
                                }
                              })
                              .map(({ node }) => node.headings)
                              .flat(1)
                              .filter(heading => {
                                if (frontmatter.depth) {
                                  return heading.depth <= frontmatter.depth;
                                }
                                return heading.depth < 3;
                              })
                          }
                        />
                      </PageHeader>
                    )}

                    {/* Component needs to be here for reference with serverside rendering and styled-components. */}

                    <Tabs
                      pathName={location.pathname}
                      items={
                        location.pathname &&
                        sortItems(
                          menuItems
                            .map(({ items }) => items)
                            .flat(1)
                            .filter(({ node }) => {
                              const pathname = location.pathname.split('/');
                              const path = `${pathname[1]}/${pathname[2]}`;
                              return node.parent.relativeDirectory === path;
                            })
                            .sort(sortByParentName)
                        )
                      }
                    />

                    <Content>{children}</Content>
                    <Footer />
                  </Container>
                </Main>
                {/** SideNav must come after main for z-index */}

                <SideNav
                  location={location}
                  pageContext={pageContext}
                  menuItems={menuItems}
                />
              </React.Fragment>
            )}
          </Location>
        </BrandThemeProvider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
    protocol: PropTypes.string,
    search: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    frontmatter: PropTypes.shape({
      name: PropTypes.string,
      intro: PropTypes.string,
    }),
  }),
};

export default Layout;
/* eslint-enable import/extensions, import/no-unresolved, import/no-unassigned-import */
