import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Breakpoints from 'components/Breakpoints';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=useMediaQuery wrap=function",
        "componentName": "useMediaQuery",
        "wrap": "function"
      }}>{`() => {
  // Object example: not all breakpoints need to be set
  const bgColor = useMediaQuery(
    {
      xs: 'greyBase',
      sm: 'blueBase',
      md: 'greenBase',
      lg: 'whiteBase',
      xl: 'redBase',
    },
    'redBase' // defaultValue
  );

  // Array examle
  const mediaQueryName = useMediaQuery(
    ['xs', 'sm', 'md', 'lg', 'xl'],
    'xs' // defaultValue
  );

  return (
    <Box bgColor={bgColor} p={5}>
      MediaQuery: {mediaQueryName}, {bgColor}
    </Box>
  );
};
`}</code></pre>
    <Breakpoints platform="scss" mdxType="Breakpoints" />
    <h2 {...{
      "id": "only-use-this-in-clientside-applications",
      "style": {
        "position": "relative"
      }
    }}>{`Only use this in clientside applications!`}</h2>
    <p>{`Although this hook will use the fallback value in serverside context. It's
recommended to use it only in clientside applications. Under the hood we use
`}<inlineCode parentName="p">{`window.matchMedia`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "array",
      "style": {
        "position": "relative"
      }
    }}>{`Array`}</h2>
    <p>{`The default value will be used when window is not available.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=useMediaQuery wrap=function",
        "componentName": "useMediaQuery",
        "wrap": "function"
      }}>{`() => {
  const bgColor = useMediaQuery(
    ['greyBase', 'blueBase', 'greenBase', 'whiteBase', 'redBase'],
    'redBase' // defaultValue
  );

  // sm and md will fallback to the value of xs
  const borderStyle = useMediaQuery(
    ['dotted', , , 'double', 'dashed'],
    'dashed' // defaultValue
  );

  const mediaQueryName = useMediaQuery(
    ['xs', 'sm', 'md', 'lg', 'xl'],
    'xs' // defaultValue
  );

  return (
    <Box
      bgColor={bgColor}
      borderStyle={borderStyle}
      borderColor="blackBase"
      borderWidth={4}
      p={5}
    >
      MediaQuery: {mediaQueryName}, {bgColor}, {borderStyle}
    </Box>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "object",
      "style": {
        "position": "relative"
      }
    }}>{`Object`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=useMediaQuery wrap=function",
        "componentName": "useMediaQuery",
        "wrap": "function"
      }}>{`() => {
  // Object example: not all breakpoints need to be set
  const bgColor = useMediaQuery(
    {
      xs: 'greyBase',
      sm: 'blueBase',
      md: 'greenBase',
      lg: 'whiteBase',
      xl: 'redBase',
    },
    'redBase' // defaultValue
  );

  // sm, md will fallback to the value of xs
  const borderStyle = useMediaQuery(
    {
      xs: 'dotted',
      lg: 'double',
      xl: 'dashed',
    },
    'dashed' // defaultValue
  );

  const mediaQueryName = useMediaQuery(
    {
      xs: 'xs',
      sm: 'sm',
      md: 'md',
      lg: 'lg',
      xl: 'xl',
    },
    'xs' // defaultValue
  );

  return (
    <Box
      bgColor={bgColor}
      borderStyle={borderStyle}
      borderColor="blackBase"
      borderWidth={4}
      p={5}
    >
      MediaQuery: {mediaQueryName}, {bgColor}, {borderStyle}
    </Box>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      