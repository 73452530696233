import React from 'react';
import { Text, Box } from 'CHAMELEON_REACT_HUB';
const Footer = () => {
  return (
    <Box
      as="footer"
      px={5}
      mt={7}
      pt={6}
      pb={7}
      borderTopWidth={1}
      borderTopColor="colorGrey20"
      borderTopStyle="solid"
    >
      <Text
        as="p"
        color="colorGreyDark"
        textAlign="right"
        size="Caption2"
        fontFamily="system"
      >
        &copy; Copyright 2020 Mediahuis NV. All rights Reserved
      </Text>
    </Box>
  );
};

export default Footer;
